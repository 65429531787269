export const contentBarSyles = {
  top: [0, 'headerHeight'],
};

export const guideStyles = {
  section: (index: number) => ({
    py: 5,
    minHeight: 'min-content !important',
    '.content': {
      minHeight: 'min-content !important',
    },
    ...(index % 2
      ? {}
      : {
          bg: 'background',
          img: {
            maxWidth: ['80%', '50%'],
            borderRadius: '5px',
          },
          h1: {
            mt: 'headerHeight',
            color: 'inherit !important',
          },
          h2: {
            variant: 'styles.h2',
            mt: [5, 5, 8],
            mb: 4,
            color: 'inherit !important',
            fontSize: '25px !important',
          },
          table: {
            border: 'none',
            td: {
              border: 'none',
              outline: 'none',
              img: {
                width: '100%',
                maxWidth: '100%',
              },
            },
          },
          '&.static-section': {
            'a, a dd': {
              outline: 'none',
            },
            'a[target=_blank]:before': {
              content: '""',
            },
          },
        }),
  }),
};
